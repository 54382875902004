<template>
  <div>
    <title>Please Login</title>
    <body class="hold-transition login-page">
      <div class="login-box">
        <div class="login-logo">
          <img width="60" src="@/assets/ikontitle.png" alt="" />
          &nbsp;
           <!--<span class="logo-lg"><b>SIM INVENTORY</b></span>-->
        </div>
        <!-- /.login-logo -->
        <div class="login-box-body">
          <p class="login-box-msg">Enter the account username and password</p>
          <div v-if="loading" class="load">
            <center>
              <img width="130" src="@/assets/loader.gif" alt="" />
            </center>
          </div>
          <br />
          <form action="#" method="post">
            <!-- <input type="text" name="csrf-token" v-bind:value="csrf"> -->
            <div class="form-group has-feedback">
              <input
                type="text"
                class="form-control"
                v-model="username"
                placeholder="Username"
              />
              <span
                class="glyphicon glyphicon-envelope form-control-feedback"
              ></span>
            </div>
            <div class="form-group has-feedback">
              <input
                type="password"
                v-on:keyup.enter="login"
                class="form-control"
                v-model="password"
                placeholder="Password"
              />
              <span
                class="glyphicon glyphicon-lock form-control-feedback"
              ></span>
            </div>
            <div class="row">
              <!-- /.col -->
              <div class="col-xs-4">
                <button
                  type="button"
                  @click="login()"
                  class="btn btn-primary btn-block btn-flat"
                >
                  Sign In
                </button>
              </div>
              <!-- /.col -->
            </div>
          </form>
        </div>
        <!-- /.login-box-body -->
      </div>
    </body>
  </div>
</template>

<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "login",
  data() {
    return {
      loading: false,
      username: "",
      password: "",
      // csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    };
  },
  methods: {
    async login() {
      this.loading = true;
      var username = this.username;
      var password = this.password;
      if (username == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Maaf, username tidak boleh kosong",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (password == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Maaf, password tidak boleh kosong",
          showConfirmButton: false,
        });
        this.loading = false;
      } else {
        const dataparam = {
          user: this.username,
          pass: this.password,
        };
        axios
          .post(
            // "http://26.183.23.191/inventory/backend/inventory/api/auth/xlogin",
            this.$apiurl + "auth/xlogin",
            dataparam
          )
          .then((resp) => {
            // console.log(resp);
            const tokenlogin = resp.data.token;
            //localStorage.setItem("token",tokenlogin)
            sessionStorage.setItem("token", tokenlogin);
            sessionStorage.setItem("kodeuser",this.username);
            localStorage.setItem("namakar",resp.data.nama_karyawan);
            localStorage.setItem("deptkar",resp.data.dept);
            this.loading = false;
            window.location.href = "/home";
          })
          .catch((err) => {
            // localStorage.removeItem("token");
            sessionStorage.removeItem("token");
            swal({
              icon: "error",
              title: "Warning",
              text: err,
              showConfirmButton: false,
            });
            this.loading = false;
          });
      }
    },
  },
};
</script>